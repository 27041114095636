export const smsTypes = [
  {
    value: 100,
    serviceId: 1,
    text: 'Token',
    description:
      'Uma solução segura para entregas rápidas de senhas de uso único, autenticação e campanhas promocionais, incluindo casas de apostas. Tempo médio de entrega: 3 segundos.',
  },
  {
    value: 200,
    serviceId: 5,
    text: 'Shortcode',
    description:
      'O SMS Shortcode garante a máxima taxa de entrega, realizando até 15 tentativas. Tempo médio de entrega: 5 minutos.',
  },
  {
    value: 300,
    serviceId: 6,
    text: 'Massivo',
    description:
      'Similar ao Shortcode, porém realiza apenas uma tentativa de entrega. Tempo médio de entrega: 1 hora e 30 minutos.',
  },
  {
    value: 400,
    serviceId: 7,
    text: 'Flash',
    description:
      'Exibe a mensagem diretamente na tela do celular, caso o dispositivo esteja desbloqueado. Se bloqueado, chega como SMS normal. Tempo médio de entrega: 5 minutos. (Obs: a operadora Vivo não garante a entrega).',
  },
  {
    value: 500,
    serviceId: 8,
    text: 'Bet',
    description: 'Destinado a campanhas de casas de apostas, realiza uma tentativa de entrega. Tempo médio de entrega: 1 hora e 30 minutos.',
  },
]

export const campaignStatus = [
  { value: 1, label: 'Aguardando envio', color: 'secondary' },
  { value: 2, label: 'Em andamento', color: 'info' },
  { value: 3, label: 'Finalizada', color: 'success' },
  { value: 4, label: 'Cancelada', color: 'error' },
  { value: 5, label: 'Em fila', color: 'info' },
  { value: 6, label: 'Enviando', color: 'info' },
  { value: 7, label: 'Erro', color: 'error' },
]

export const isPausedStatus = [
  { value: false, label: 'Não' },
  { value: true, label: 'Sim' },
]

export const hasInteractionStatus = [
  { value: 0, label: 'Não' },
  { value: 1, label: 'Sim' },
]

export const serviceType = {
  SMSToken: 1,
  Voice30s: 2,
  Voice60s: 3,
  Sanitizer: 4,
  SMSShortcode: 5,
  SMSMassive: 6,
  SMSFlash: 7,
  SMSBet: 8,
}

export const voiceTypes = [
  { value: 1, text: 'URA Simples' },
  { value: 2, text: 'URA Inteligente' },
]

export const voiceType = {
  Simple: 1,
  Intelligent: 2,
}

export const voiceServices = [
  { value: serviceType.Voice30s, text: 'Voz 30 segundos' },
  { value: serviceType.Voice60s, text: 'Voz 60 segundos' },
]

export const voiceDialSpeeds = [
  { value: 1, text: 'Lenta' },
  { value: 2, text: 'Normal' },
  { value: 3, text: 'Rápida' },
]

export const smsTemplate = {
  Pending: 1,
  Approved: 2,
  Refused: 3,
}

export const smsTemplateType = {
  Default: 1,
  Bet: 2,
}

export const smsTemplateStatus = [
  { value: smsTemplate.Pending, text: 'Aguardando Análise' },
  { value: smsTemplate.Approved, text: 'Aprovado' },
  { value: smsTemplate.Refused, text: 'Recusado' },
]

export const smsTemplateTypes = [
  { value: smsTemplateType.Default, text: 'Padrão' },
  { value: smsTemplateType.Bet, text: 'Bet' },
]

export const startCredit = {
  sent50: 'bonus-50-sms-gratis',
}

export const uraAction = {
  endCall: 1,
  playAudio: 2,
  sendSms: 3,
  playAudioSendSms: 4,
  fowardUra: 5,
  playAudioSendRequest: 6,
}

export const uraActions = [
  { id: uraAction.endCall, value: 'Desligar' },
  { id: uraAction.playAudio, value: 'Tocar Áudio + Desligar' },
  { id: uraAction.playAudioSendRequest, value: 'Tocar Áudio + Webhook' },
  { id: uraAction.playAudioSendSms, value: 'Tocar Áudio + SMS' },
  { id: uraAction.sendSms, value: 'SMS + Desligar' },
]

export const uraOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

export const userLevels = [
  { id: 1, text: 'Basic' },
  { id: 2, text: 'Starter' },
  { id: 3, text: 'Essential' },
  { id: 4, text: 'Advanced' },
  { id: 5, text: 'Premium' },
  { id: 6, text: 'Elite' },
  { id: 7, text: 'Diamond' },
  { id: 8, text: 'Expert' },
]

export const isAffiliateItems = [
  { value: false, text: 'Não' },
  { value: true, text: 'Sim' },
]

export const uraSimpleSendSmsEnum = {
  No: 0,
  AllNumbers: 1,
  NumbersServed: 2,
}

export const uraSimpleSendSmsItems = [
  { value: uraSimpleSendSmsEnum.No, text: 'Não' },
  { value: uraSimpleSendSmsEnum.AllNumbers, text: 'Todos os números' },
  { value: uraSimpleSendSmsEnum.NumbersServed, text: 'Apenas números atendidos' },
]

export const userAuthMethod = {
  System: 'system',
  GoogleAuth: 'gauth',
}
